body {
  margin: 0;
  min-height: 100vh;
  font-family: "Roboto", "Poiret" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay !important;
  overscroll-behavior: none !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.main-container {
  overflow: overlay !important;
  overscroll-behavior: none !important;
}

a {
  text-decoration: none !important;
}

.sublined {
  text-decoration: underline;
}

.break {
  overflow-wrap: break-word;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.half-spin {
  animation: spin 0.5s linear;
}

.rotate-up {
  transform: rotate(-180deg); /* Flecha hacia arriba */
  transition: transform 0.5s ease;
}

.rotate-down {
  transform: rotate(0deg); /* Flecha hacia abajo */
  transition: transform 0.5s ease;
}

@keyframes half-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poiret+One&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import "./bootstrap-custom.scss";
