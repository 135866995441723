$theme-colors: (
  "primary": #242424,
  "contrast": #ffffff,
  "secondary": #999999,
  "success": #1f6909,
  "successl": white,
  "danger": #7c2700,
  "grey": #eeeeee,
  "grey2": #f0f0f0,
);

:root {
  --primary: #242424;
  --danger: #7c2700;
  --notification: #ec7741;
  --white: #fff;
  --secondary: #999999;
}

.border-success.bg-successl.accordion-item {
  h2 {
    button {
      background-color: #1f6909 !important;
      color: white;
      border-bottom: 1px solid #185306;
    }

    button::after {
      color: white;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e");
    }
  }
}

.accordion-button {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.accordion-item {
  h2 {
    button[aria-expanded="true"] {
      background-color: #bdbdbd !important;
      color: var(--primary);
    }
  }
}

.accordion-button:focus {
  box-shadow: none !important;
}

.pointer:hover {
  cursor: pointer;
}

.shadow-hover:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.size-hover {
  transition: all 0.5s;
}

.size-hover:hover {
  transform: scale(1.02);
}

.form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.text-10 {
  font-size: 0.625rem;
}

.text-12 {
  font-size: 0.75rem;
}

.text-14 {
  font-size: 0.875rem;
}

p {
  font-size: 0.875rem;
}

.label,
.form-label {
  font-size: 0.875rem !important;
  margin-top: 5px;
  margin-bottom: 0px;
}

div {
  transition: all 0.5s !important;
}

@import "~bootstrap/scss/bootstrap.scss";
