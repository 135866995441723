.custom-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;

  .custom-checkbox {
    margin-right: 5px;
    display: flex;
  }

  .form-check-input {
    width: 13px;
    height: 13px;
    margin: auto;
  }
}
