.client-card-actions {
  display: flex;
}

.client-card-actions-mobile {
  display: flex;
}

.client-card-actions-mobile button {
  flex-wrap: wrap;
  width: 100%;
}
